.monster-detail-table {
  width: 60vw;
  display: table;
  table-layout: fixed;
  padding: 10px;
  margin-bottom: 20px;
  background: #2b2b2b;
  border-radius: 10px;
  border: none;
  border-collapse: separate;
  outline: none;
  animation: fadeInAnimation 1s linear;

  table {
    width: 100%;
  }

  th {
    color: white;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  td {
    width: 100%;
    padding: 0 10px 0 10px;
    text-align: center;
    p {
      width: 100%;
      margin: 0;
      border-radius: 10px;
      color: white;
      background: #1e1e1e;
    }
  }

  .monster-wiki-divider {
    width: 100%;

    div {
      height: 1px;
      margin: 10px 0 10px 0;

      background: #707070;
    }
  }

  .monster-title-row {
    th:first-child {
      width: 50%;
      padding-left: 20px;
      font-size: 18px;
      text-align: start;
    }

    th:nth-child(2) {
      width: 35%;
    }
  }

  tr {
    th {
      height: 30px;
      padding: 0;
    }

    .monster-status-col {
      vertical-align: top;

      th {
        width: 300px;
      }

      td {
        width: 300px;
      }
    }

    .monster-exp-col {
      vertical-align: bottom;
    }

    .monster-location-col {
      vertical-align: top;

      .location {
        height: 100%;
        width: 100%;
        padding: 5px 5px 0 5px;
        display: flex;
        flex-wrap: wrap;
        line-height: 2.5;

        .map {
          span {
            padding-left: 15px;
            background: #1e1e1e;
            border-radius: 20px;
            color: white;
          }
        }
      }
    }

    .monster-element-col {
      vertical-align: top;

      tr {
        th {
          height: 30px !important;
        }
        td {
          height: 30px !important;
          padding: 0 10px 0 10px;
          p {
            margin: 0;
            background: #1e1e1e;
            color: white;
          }
        }
      }
    }

    .common-drop-table {
      display: flex;
      flex-wrap: wrap;
      line-height: 2.5;

      .drop-detail {
        padding: 2px 0 2px 0;

        &-btn {
          padding: 0 10px 0 10px;
          display: flex;
          flex-direction: row;
          background: #444;
          border: none;
          border-radius: 20px;
          color: white;
          outline: none;
          transition: all 0.1s ease-out;

          &:hover {
            background: wheat;
            border-radius: 30px;
          }

          &:active {
            background: orange;
            border-radius: 30px;
          }

          span {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
