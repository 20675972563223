@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.MuiPagination-root {
  color: white !important;
  .MuiPagination-ul {
    background: white !important;
    border-radius: 60px;
  }

  .MuiPagination-text {
    color: white !important;
  }
}

.monster-wiki-container {
  padding-top: 3% !important;
  padding-bottom: 3% !important;

  // NAME FILTER SECTION

  .monster-filter {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:last-child {
      margin-bottom: 20px;
    }

    .filter-title {
      height: 5vh;
      width: 20%;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #2e2e2e;
      border-radius: 60px;
      font-size: 22px;

      span {
        color: white;
        padding-top: 5px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    input {
      margin: 0 !important;
    }

    .filter-input {
      * {
        margin: 0;
        border: none !important;
        outline: none !important;
      }

      height: 80%;
      width: 80%;
      background: white;
      border-radius: 30px;

      input.form-control {
        height: 5vh;
        background: white !important;
        border-radius: 30px !important;

        &:focus {
          text-decoration: none !important;
          border: none !important;
          outline: none !important;
        }
      }
    }

    .range-filter-group {
      width: 80%;
      display: flex;
      flex-direction: row;

      * {
        margin-top: 0;
        margin-bottom: 0;
      }

      .range-filter {
        margin-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &-title {
          height: 5vh;
          width: 50%;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #2e2e2e;
          border-radius: 30px;

          span {
            color: white;
            border-radius: 20px;
          }
        }

        input.form-control {
          height: 5vh;
          background: white !important;
          border-radius: 30px !important;
          text-align: center;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &:focus {
            text-decoration: none !important;
            border: none !important;
            outline: none !important;
          }
        }
      }
    }
  }

  .no-monster-data {
    height: 100px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(143, 143, 143);
    border-radius: 60px;
    animation: fadeInAnimation 1s linear;

    p {
      margin-top: 20px;
    }
  }

  .monster-data-container {
    width: 100%;

    .monster-title {
      .MuiGrid-root {
        .MuiGrid-item {
          background: #222;
          color: white;

          &:first-child {
            padding-left: 5px;
          }
        }
      }
    }

    .monster-detail {
      background: #707070;
      color: white;

      .MuiGrid-root {
        .MuiGrid-item {
          color: white;
          border: solid black 1px;
          text-align: center;

          &:nth-child(2n + 1) {
            padding-left: 5px;
            background: #222;
          }
        }
      }
    }

    .monster-detail-table {
      width: 100%;
      display: table;
      table-layout: fixed;
      padding: 10px;
      margin-bottom: 20px;
      background: #2b2b2b;
      border-radius: 10px;
      border: none;
      border-collapse: separate;
      outline: none;
      animation: fadeInAnimation 1s linear;

      table {
        width: 100%;
      }

      th {
        font-size: 14px;
        font-weight: 600;
      }

      td {
        width: 100%;
        padding: 0 10px 0 10px;
        p {
          width: 100%;
          margin: 0;
          border-radius: 10px;
          color: white;
          background: #1e1e1e;
        }
      }

      .monster-wiki-divider {
        width: 100%;

        div {
          height: 1px;
          margin: 10px 0 10px 0;

          background: #707070;
        }
      }

      .monster-title-row {
        th:first-child {
          width: 50%;
          padding-left: 20px;
          font-size: 18px;
          text-align: start;
        }

        th:nth-child(2) {
          width: 35%;
        }
      }

      tr {
        th {
          height: 30px;
          padding: 0;
        }

        .monster-status-col {
          th {
            width: 300px;
          }

          td {
            width: 300px;
          }
        }

        .monster-exp-col {
          vertical-align: bottom;
        }

        .monster-location-col {
          vertical-align: top;

          .location {
            height: 100%;
            width: 100%;
            padding: 5px 5px 0 5px;
            display: flex;
            flex-wrap: wrap;
            line-height: 2.5;

            .map {
              span {
                padding-left: 15px;
                background: #1e1e1e;
                border-radius: 20px;
                color: white;
              }
            }
          }
        }

        .monster-element-col {
          vertical-align: top;

          tr {
            th {
              height: 30px !important;
            }
            td {
              height: 30px !important;
              padding: 0 10px 0 10px;
              p {
                margin: 0;
                background: #1e1e1e;
              }
            }
          }
        }

        .common-drop-table {
          display: flex;
          flex-wrap: wrap;
          line-height: 2.5;

          .drop-detail {
            width: auto;
            padding: 2px 0 2px 0;

            &-btn {
              padding: 0 10px 0 10px;
              background: #444;
              border: none;
              border-radius: 60px;
              color: white;
              outline: none;
              white-space: nowrap;
              transition: all 0.1s ease-out;

              &:hover {
                background: wheat;
                border-radius: 30px;
              }

              &:active {
                background: orange;
                border-radius: 30px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .monster-element-filter {
    width: auto;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1600px) {
  .monster-element-filter {
    width: 77.5vw;
    margin-top: 10px;
  }
}
