@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

.MuiPagination-root {
  color: white !important;
  .MuiPagination-ul {
    background: white !important;
    border-radius: 60px;
  }

  .MuiPagination-text {
    color: white !important;
  }
}

.item-wiki-container {
  

  .filter-title {
    height: 6vh;
    width: 20%;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2b2b2b;
    border-radius: 60px;
    font-size: 22px;

    span {
      color: white;
      padding-top: 5px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .search-item-options {
    width: 100%;
    margin: 0 0 0 0 !important;
  }

  .item-type-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .job-filter {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .slot-filter {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .name-filter {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    * {
      margin: 0 !important;
    }

    .item-filter-input {
      width: 100%;

      input {
        width: 100% !important;
        margin-left: 20px !important;
      }
    }
  }
}

div.modal-content,
div.modal-dialog {
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.modal-dialog .modal-content {
  width: auto !important;
  box-shadow: none !important;
}

.modal-dialog .modal-content .item-info-card {
  height: 417px;
  width: 202px;
  display: block;
  background-image: url("../assets/iteminfo.png");
  background-size: cover;
  background-position: center;
}

table.item-info-status {
  border: none;
}

.item-info {
  &-card {
    padding-left: 11px;

    p {
      font-size: 10px;
    }
  }

  &-status {
    * {
      font-size: 10px;
      color: #6464c8;
    }

    b {
      font-weight: 700;
    }
  }

  &-options {
    * {
      font-size: 10px;
      color: #6400b4;
      border: none;
    }
  }

  &-name {
    padding-top: 4.5vh;
  }

  &-feed-value {
    color: palevioletred;
    margin-bottom: 0px;
  }

  &-price {
    color: #888;

    p {
      margin-bottom: 0px;
    }
  }
}
