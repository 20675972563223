@font-face {
  font-family: fc_palette;
  src: url("./fonts/Opun-Regular.ttf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: fc_palette;
}

.frame{
  height: 100vh;
}

.frameEm{
  height: 100vh !important;
}
 ::-webkit-scrollbar {
        height: 0px;
        width: 5px;
        
}

::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgb(75, 85, 99,0.4);
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgb(0, 0, 0,0.4);
}

@media only screen and (max-width: 800px){
  .navbar-item {
      width: 20%;
      border: 1px solid #000;
      font-size: 12px;
  }
}
@media(max-width: 1000){
  .container-fluid.item-wiki-container , .item-wiki-container {
     padding: 10px!important; 
     width: auto;
  }
}
/* body {
  width: 100%;
  background-image: linear-gradient(to bottom right, #ff7f50, white);
  background-repeat: no-repeat;
  background-size: cover cover;
} */


@media only screen and (max-width: 600px) {
  .navbar-item {
      width: 20%;
      border: 1px solid #000;
      font-size: 12px;
  }

  .navbar-title {
      font-size: 11px;
  }

  .item-table-collection .item-table td {
      color: #fff;
      font-size: 10px;
      font-family: fc_palette;
      font-weight: 500;
      text-align: center;
  }

  table th {
      font-size: 0.4em;
  }

  .item-wiki-container .filter-title span {
      color: #fff;
      padding-top: 5px;
      font-size: 8px;
      font-weight: 600;
  }

  .monster-wiki-container .monster-filter .filter-title span {
      color: #fff;
      padding-top: 5px;
      font-size: 6px;
      font-weight: 600;
  }

  .monster-wiki-container .monster-filter .range-filter-group .range-filter-title span {
      color: #fff;
      border-radius: 20px;
      font-size: 10px;
  }

  .drop-by span {
      font-size: 10px;
  }

  tr.description-row span {
      font-size: 9px !important;
      padding-left: 15px;
  }

  .item-table-collection .item-table .application-job-row .applicable-job span {
      color: #fff !important;
      font-size: 10px !important;
      font-weight: 600;
  }

  .monster-wiki-container .monster-data-container .monster-detail-table th {
      font-size: 8px;
      font-weight: 600;
  }
  .monster-wiki-container .monster-data-container .monster-detail-table td p {
  width: 100%;
  margin: 0;
  border-radius: 10px;
  color: #fff;
  background: #1e1e1e;
  font-size: 10px;
}
.monster-wiki-container .monster-data-container .monster-detail-table tr .common-drop-table .drop-detail-btn {
  padding: 0 10px;
  background: #444;
  border: none;
  border-radius: 60px;
  color: #fff;
  outline: none;
  white-space: nowrap;
  transition: all .1s ease-out;
  font-size: 11px;
}
.monster-wiki-container .monster-data-container .monster-detail-table tr .monster-location-col {
  vertical-align: top;
  position: relative;
  left: 4%;
  top: 8px;
}
.monster-wiki-container .monster-data-container .monster-detail-table tr .monster-location-col .location {
  height: 100%;
  width: 100%;
  padding: 0px;
  display: block;
  flex-wrap: wrap;
  line-height: 20px;
  position: absolute;
  text-align: left;
}

.monster-wiki-container .monster-data-container .monster-detail-table tr .monster-element-col {
  vertical-align: top;
  position: relative;
  left: -26px;
}

.monster-wiki-container .monster-data-container .monster-detail-table tr .monster-location-col .location .map span {
  padding-left: 15px;
  background: #1e1e1e;
  border-radius: 20px;
  color: #fff;
  font-size: 8px !important;
}
}

@media only screen and (max-width: 400px) {
.monster-wiki-container .monster-data-container .monster-detail-table tr .monster-location-col {
  vertical-align: top;
  position: relative;
  left: -55px;
  top: 8px;
}
.monster-wiki-container .monster-data-container .monster-detail-table tr .monster-location-col .location {
  height: 100%;
  width: 100%;
  padding: 0px;
  display: block;
  flex-wrap: wrap;
  line-height: 15px;
  position: absolute;
  text-align: left;
}
.monster-wiki-container .monster-data-container .monster-detail-table tr .monster-location-col .location .map span {
  padding-left: 15px;
  background: #1e1e1e;
  border-radius: 20px;
  color: #fff;
  font-size: 5px !important;
}
}

@media only screen wand(max-width: 1000) {
  .item-wiki-container {
      padding: 10px !important;
      width: auto !important;
  }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  margin-top: 10px;
}


.redirect.text-center {
  padding: 10px;
  color: white !important;
  font-size: 25px;
  display:none !important;
}

.redirect.text-center a{
  color: white !important;
}

.type_10{
  background-color: #3cb371 !important;
}
.type_15 {
  background-color: #ff2e44 !important;
}

/*padding: 20px 300px 0 300px !important;*/