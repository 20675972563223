@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

.search-item-options {
  width: 100%;
  margin-top: 10vh;
  margin-bottom: 5vh;
}

.data-table {
  justify-content: center;
  align-items: center;
}

// table {
//   width: 100%;
// }

// table,
// td {
//   border-collapse: collapse;
// }

// table tr th {
//   padding: 1vw 2vw 1vw 2vw;
//   text-align: center;
//   color: white;
//   font-weight: 700;
//   border: 1px solid #888;
//   background-color: #222;
// }

// table tr td {
//   text-align: center;
// }

// table tr:nth-child(odd) {
//   background-color: rgba(197, 197, 197, 0.5);
// }

div.modal-content,
div.modal-dialog {
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.modal-dialog .modal-content {
  width: auto !important;
  box-shadow: none !important;
}

.modal-dialog .modal-content .item-info-card {
  height: 417px;
  width: 202px;
  display: block;
  background-image: url("../assets/iteminfo.png");
  background-size: cover;
  background-position: center;
}

table.item-info-status {
  border: none;
}

.item-info {
  &-card {
    padding-left: 11px;

    p {
      font-size: 10px;
    }
  }

  &-status {
    * {
      font-size: 10px;
      color: #6464c8;
    }

    b {
      font-weight: 700;
    }
  }

  &-options {
    * {
      font-size: 10px;
      color: #6400b4;
      border: none;
    }
  }

  &-name {
    padding-top: 4.5vh;
  }

  &-feed-value {
    color: palevioletred;
    margin-bottom: 0px;
  }

  &-price {
    color: #888;

    p {
      margin-bottom: 0px;
    }
  }
}
