html,
body {
  height: 100%;
  background: #202020 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to bottom right, #ff7f50, white);
  background-repeat: no-repeat;
  background-attachment: fixed; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  /* background: url(images/bg.jpg) no-repeat center center fixed;  */
  /* background-image: linear-gradient(to bottom right, #ff7f50, white); */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover; */
  /* background-size: auto 100%; */
}
