@font-face {
  font-family: fc-palette;
  src: url("../fonts/fc_palette.ttf");
}

.navbar {
  width: 100%;
  height: 70px;
  padding: 0 !important;
  background-color: rgb(51, 51, 51);
  display: flex;
  align-items: center !important;
  justify-content: start !important;

  &-title {
    height: 100%;
    width: 10%;
    background: #3b3b3b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: wheat;
    font-size: 20px;
    font-weight: 600;
  }

  &-item {
    position: relative;
    height: 100%;
    width: 10%;
    background: #3b3b3b;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid black 1px;
    color: wheat;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.2s;

    &:focus {
      background: #ffffff21 !important;
      color: wheat !important;
      outline: none;
    }

    &:active {
      background: #ffffff21 !important;
      color: wheat !important;
    }

    &:hover {
      background: #707070;
      transition: all 0.2s;
    }

    &::after {
      content: "";
      position: absolute;
      height: 5px;
      width: 0%;
      bottom: 0;
      left: 0;
      left: 50%;
      transform: translateX(-50%);
      background: wheat;
      transition: all 0.3s ease;
    }

    &:hover::after {
      width: 100%;
    }

    &[isSelected="true"] {
      &::after {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    height: 50px;
    display: flex;

    &-title {
      height: 100%;
      width: 20%;
      background: #3b3b3b;
      display: flex;
      align-items: center;
      justify-content: center;
      color: wheat;
      font-size: 16px;
      font-weight: 600;
    }

    &-item {
      height: 100%;
      width: 10%;
      background: #3b3b3b;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid black 1px;
      color: wheat;
      font-size: 18px;
      font-weight: 600;
      transition: all 0.2s;

      &:focus {
        background: #ffffff21 !important;
        color: wheat !important;
        outline: none;
      }

      &:active {
        background: #ffffff21 !important;
        color: wheat !important;
      }

      &:hover {
        background: #707070;
        transition: all 0.2s;
      }

      &[menu="ITEMS"] {
        &:after {
          content: "";
          width: 0px;
          height: 5px;
          position: absolute;
          top: 100%;
          left: 10%;
          background: rgb(248, 112, 0);
          transition: 0.2s;
        }

        &:hover:after {
          width: 10%;
        }
      }

      &[menu="MONSTERS"] {
        &:after {
          content: "";
          width: 0px;
          height: 5px;
          position: absolute;
          top: 100%;
          left: 20%;
          background: rgb(248, 112, 0);
          transition: 0.2s ease-in;
        }

        &:hover:after {
          width: 10%;
          transition: 0.3s;
        }
      }
    }
  }
}
