.data-table {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.item-table-collection {
  display: table;
  border: none;

  .item-table {
    width: 100%;
    display: table;
    margin-bottom: 20px;
    padding: 10px;
    border-collapse: separate;
    table-layout: fixed;
    background: #2e2e2e;
    border-radius: 10px;

    * {
      border: none;
    }

    td {
      color: white;
      font-size: 16px;
      font-family: fc_palette;
      font-weight: 500;
      text-align: center;
    }

    th {
      color: white;
      font-weight: 600;
      text-align: center;
    }

    tbody {
      th {
        height: 30px;
        margin: 0;
        padding: 0;
      }
    }

    .item-row-divider {
      padding: 10px 0 10px 0;

      div {
        height: 1px;
        background: #707070;
      }
    }

    .item-title-row {
      border-radius: 10px !important;
      .title {
        th {
          padding-left: 5px;
          border-radius: 10px;
          font-size: 18px;
          text-align: start;

          span {
            margin-left: 5px;
          }
        }
      }
    }

    .item-detail-row {
      td {
        padding: 5px 0 5px 0;

        p {
          width: 100%;
          margin: 0;
          background: #1e1e1e;
          border-radius: 10px;
        }
      }
    }
    .item-status-row {
      td {
        padding: 5px 0 5px 0;

        p {
          width: 100%;
          margin: 0;
          background: #1e1e1e;
          border-radius: 10px;
        }
      }
    }

    .application-job-row {
      .applicable-job {
        padding: 5px;
        display: flex;
        flex-wrap: wrap;

        span {
          color: white !important;
          font-size: 16px !important;
          font-weight: 600;
        }
      }
    }
    .description-row {
      td {
        padding: 10px 0 10px 0;
        text-align: left;
      }
    }

    .evolution-row {
      .evolution-table {
        display: table;
        table-layout: fixed;
        border-collapse: collapse;

        .evo-container {
          display: flex;
          flex-direction: column;
          justify-content: center;

          &:not(:first-child) {
            margin-top: 10px;
          }

          .evo-title {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
              background: transparent;
              border-radius: 5px;
              color: white;
              transition: all 0.1s ease-out;

              &:hover {
                background: rgba(255, 255, 255, 0.397);
                transition: all 0.1s ease-out;
              }

              &:active {
                background: rgba(255, 255, 255, 0.801);
              }

              &:focus {
                outline: none;
              }
            }
          }

          .evo-items {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            button {
              margin: 0 5px 10px 5px;
              background: transparent;
              border-radius: 5px;
              transition: all 0.1s ease-out;

              &:hover {
                background: rgba(255, 255, 255, 0.425);
                transition: all 0.1s ease-in;
              }
            }
          }
        }
      }
    }

    .drop-by-row {
      display: table-row;

      .drop-by {
        display: flex;
        flex-wrap: wrap;
        padding: 5px;

        button {
          margin: 0 5px 5px 0;
          padding: 0 10px 0 10px;
          background: #444;
          border-radius: 30px;
          border: none;
          color: white;
          font-size: 14px;
          transition: all 0.1s ease-out;

          &:hover {
            background: #555;
            transition: all 0.1s ease-in;
          }

          &:active {
            background: #333;
            transition: all 0.1s ease-in;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
